<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Lives Changed</h3>
            <button class="btn btn-primary" @click="exportReport">
                Export CSV
            </button>
        </div>

        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="livesChangedTotalCount > 0">
                    <paginator :rowCount="livesChangedTotalCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                    <list-table :livesChanged="livesChanged" :livesChangedTotals="livesChangedTotals"></list-table>
                    <paginator :rowCount="livesChangedTotalCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListTable from './includes/ListTable'
import Paginator from '../includes/pagination/Paginator'
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'MainPage',
  components: {
    ListTable,
    Paginator
  },
  computed: {
    ...mapGetters({
      livesChangedTotals: 'livesChanged/getLivesChangedTotals',
      livesChanged: 'livesChanged/getLivesChanged',
      livesChangedTotalCount: 'livesChanged/getLivesChangedTotalCount',
      paginationCurrentPage: 'livesChanged/getPaginationCurrentPage',
      paginationPerPage: 'livesChanged/getPaginationPerPage'
    }),
    test () {
      return this.livesChanged && this.livesChanged.length > 0
    }
  },
  mounted () {
    this.loadLivesChanged()
    this.loadLivesChangedTotals()
  },
  methods: {
    ...mapMutations({
      setPaginationActivePage: 'livesChanged/setPaginationActivePage',
      setPaginationPerPage: 'livesChanged/setPaginationPerPage'
    }),
    ...mapActions({
      loadLivesChanged: 'livesChanged/loadData',
      loadLivesChangedTotals: 'livesChanged/loadTotals',
      downloadReport: 'livesChanged/downloadReport'
    }),
    exportReport () {
      this.downloadReport()
    },
    perPageChanged (perPage) {
      this.setPaginationPerPage(perPage)
      this.loadLivesChanged()
      this.loadLivesChangedTotals()
    },
    pageChanged (page) {
      this.setPaginationActivePage(page)
      this.loadLivesChanged()
      this.loadLivesChangedTotals()
    }
  }
}
</script>

<style scoped>

</style>
