import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'

Vue.use(Vuex)
Vue.use(VueMoment)
export default {
  namespaced: true,
  state: {
    visible: false,
    rates: [],
    trainer: {
      name: ''
    }
  },
  mutations: {
    hidePopup (state) {
      state.visible = false
    },
    showPopup (state) {
      state.visible = true
    },
    setRates (state, rates) {
      state.rates = rates
    },
    setTrainer (state, trainer) {
      state.trainer = trainer
    }
  },
  actions: {},
  getters: {
    visible (state) {
      return state.visible
    },
    rates (state) {
      return state.rates
    },
    trainer (state) {
      return state.trainer
    }
  }
}
