<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group id="input-group-2" label="Date Range" label-for="input-2">
                <date-range-picker
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                    singleDatePicker="range"
                    :timePicker="false"
                    :timePicker24Hour="true"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="false"
                    v-model="filters.date.selected"
                    :linkedCalendars="true"
                >
                    <template v-slot:input="picker" style="min-width: 350px;">
                        {{ formatDateDefault(picker.startDate) }} - {{ formatDateDefault(picker.endDate) }}
                    </template>
                </date-range-picker>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group id="input-group-2" label="Currency" label-for="input-2">
                <b-form-select v-model="filters.currency.selected" :options="filters.currency.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'SplitsFilter',
  components: {
    DateRangePicker
  },
  data () {
    return {
      filters: {
        currency: {
          selected: '',
          options: [
            {value: 'nzd', text: 'NZD'},
            {value: 'usd', text: 'USD'},
            {value: 'aud', text: 'AUD'},
            {value: 'cad', text: 'CAD'},
            {value: 'gbp', text: 'GBP'},
            {value: 'eur', text: 'EUR'},
            {value: 'sgd', text: 'SGD'},
            {value: 'nok', text: 'NOK'}
          ]
        },
        date: {
          selected: {
            startDate: '',
            endDate: ''
          },
          options: {
            opens: 'center'
          }
        }
      }
    }
  },
  watch: {
    filters: {
      handler: function (value) {
        this.$store.commit('singleTrainerSplits/setFilterCurrency', value.currency.selected)
        this.$store.commit('singleTrainerSplits/setFilterDateRange', value.date.selected)
      },
      deep: true
    }
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    initFilter () {
      this.filters.currency.selected = this.$store.getters['singleTrainerSplits/getFilterCurrency']
      this.filters.date.selected.startDate = this.$store.getters['singleTrainerSplits/getFilterDateRange'].startDate
      this.filters.date.selected.endDate = this.$store.getters['singleTrainerSplits/getFilterDateRange'].endDate
    },
    submitFilter () {
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>

</style>
