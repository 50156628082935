<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Dashboard Report</h3>
        </div>
        <summary-wrapper></summary-wrapper>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import SummaryWrapper from './summary/SummaryWrapper'
export default {
  name: 'MainPage',
  components: {
    SummaryWrapper
  },
  mounted () {
    this.loadActiveTrainers()
  },
  methods: {
    ...mapActions({
      loadActiveTrainers: 'dashboard/loadData'
    })
  }
}
</script>

<style scoped>

</style>
