import axios from 'axios'
import store from '../store/index'

export default class APIService {
    baseURL = ''
    resource = ''
    token = ''

    constructor () {
      this.baseURL = process.env.VUE_APP_API_HOST + '/api'
    }

    setAccessToken () {
      this.token = store.getters.getAccessToken
    }

    getGeneralHeaders () {
      return {
        'Accept': 'application/json'
      }
    }

    getAuthHeaders () {
      this.setAccessToken()
      return {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token
      }
    }

    getMultipartHeaders () {
      this.setAccessToken()
      return {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token,
        'Content-Type': 'multipart/form-data'
      }
    }

    get (url = '', data = []) {
      this.setAccessToken()
      return axios.get(this.baseURL + '/' + this.resource + url, {
        params: data,
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + this.token
        }
      })
    }

    post (payload, url = '', headers = this.getAuthHeaders()) {
      return axios.post(this.baseURL + '/' + this.resource + url, payload, {
        headers: headers
      })
    }

    put (payload, id, headers = this.getAuthHeaders()) {
      console.log(headers)
      return axios.put(this.baseURL + '/' + this.resource + '/' + id, payload, {
        headers: headers
      })
    }

    delete (id, headers = this.getAuthHeaders()) {
      return axios.delete(this.baseURL + '/' + this.resource + '/' + id, {
        headers: headers
      })
    }
}
