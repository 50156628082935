<template>
    <div>
        <summary-filter @submit="filterSubmit"></summary-filter>
        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <summary-content></summary-content>
            </div>
        </div>
    </div>
</template>

<script>

import SummaryFilter from './SummaryFilter'
import SummaryContent from './SummaryContent'
import {mapActions} from 'vuex'

export default {
  name: 'SummaryWrapper',
  components: {
    SummaryContent,
    SummaryFilter
  },
  mounted () {
    this.refreshMonths()
  },
  methods: {
    ...mapActions({
      loadData: 'dashboard/loadData',
      refreshMonths: 'dashboard/refreshMonths'
    }),
    filterSubmit () {
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
