import APIService from './APIService'

export default class LivesChangedDailyService {
  getAll (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('lives-changed-daily', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getTotals (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('lives-changed-daily/totals', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getExportToken (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('lives-changed-daily/export', payload)
        .then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
    })
  }
}
