var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-sm table-hover lives-changed-table"},[_c('thead',[_c('tr',{staticClass:"total-sec"},[_c('td',{attrs:{"colspan":"4"}},[_vm._v("\n                Total\n            ")]),_vm._v(" "),_vm._l((_vm.columnDates),function(columnDate,key){return _c('td',{key:key,staticClass:"text-right"},[_vm._v("\n                "+_vm._s(_vm.addThousandSeparator(_vm.getLivesChangedValueByDate(
                _vm.livesChangedTotals,
                columnDate
                )))+"\n            ")])})],2),_vm._v(" "),_c('tr',{staticClass:"table-title"},[_c('th',{staticClass:"lives-changed-table__label-cell",attrs:{"scope":"col"}},[_vm._v("#")]),_vm._v(" "),_c('th',{staticClass:"lives-changed-table__label-cell",attrs:{"scope":"col"}},[_vm._v("Account ID")]),_vm._v(" "),_c('th',{staticClass:"lives-changed-table__label-cell",attrs:{"scope":"col"}},[_vm._v("Trainer Name")]),_vm._v(" "),_c('th',{staticClass:"text-right lives-changed-table__value-cell",attrs:{"scope":"col"}},[_vm._v("Cumulative")]),_vm._v(" "),_vm._l((_vm.columnDates),function(columnDate,key){return _c('th',{key:key,staticClass:"text-right lives-changed-table__value-cell",attrs:{"scope":"col"}},[_c('span',[_vm._v("\n                    "+_vm._s(_vm.$moment(columnDate).format('DD MMM'))+"\n                ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$moment(columnDate).format('YYYY')))])])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.livesChanged),function(livesChangedRecord,key){return _c('tr',{key:livesChangedRecord.account_id},[_c('td',[_vm._v(_vm._s(((parseInt(key) + 1) + ((_vm.currentPage - 1) * _vm.perPage))))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(livesChangedRecord.account_id))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(livesChangedRecord.trainer_name))]),_vm._v(" "),_c('td',{staticClass:"text-right"},[_vm._v("\n                "+_vm._s(_vm.addThousandSeparator(_vm.getLivesChangedTotalByList(
                livesChangedRecord.daily_count
                )))+"\n            ")]),_vm._v(" "),_vm._l((_vm.columnDates),function(columnDate,key){return _c('td',{key:key,staticClass:"text-right"},[_vm._v("\n                "+_vm._s(_vm.addThousandSeparator(_vm.getLivesChangedValueByDate(
                livesChangedRecord.daily_count,
                columnDate
                )))+"\n            ")])})],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }