<template>
    <div class="table-responsive">
        <table class="table table-sm dashboard-summary-table">
            <thead>
            <tr class="table-title">
                <th scope="col"></th>
                <th class="text-right dashboard-summary-table__cell" scope="col" v-for="month in months"
                    :key="month.index">{{month.label}}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th scope="row" class="dashboard-summary-table__left-title">Active Trainers</th>
                <th colspan="12"></th>
            </tr>
            <tr>
                <td>Number of active trainers</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{getInt(getMetricValue('active_trainers_count',month.index))}}
                </td>
            </tr>
            <tr>
                <td>Number of hard active trainers</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{getInt(getMetricValue('hard_active_trainers_count', month.index))}}
                </td>
            </tr>
            <tr>
                <td>Number of soft active trainers</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{getInt(getMetricValue('soft_active_trainers_count',month.index))}}
                </td>
            </tr>
            <tr>
                <th scope="row" class="dashboard-summary-table__left-title">Revenue</th>
                <th colspan="12"></th>
            </tr>
            <tr>
                <td>Annualised Total Revenue as of Last Month</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('annualized_revenue',month.index))}}
                </td>
            </tr>
            <tr>
                <td>Annualized MRR as of last month</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('12_months_mrr',month.index))}}
                </td>
            </tr>
            <tr>
                <th scope="row" class="dashboard-summary-table__left-title">Churn</th>
                <th colspan="12"></th>
            </tr>
            <tr>
                <td>Hard Churn Rate</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{getMetricValue('hard_churn_rate',month.index)}} %
                </td>
            </tr>
            <tr>
                <td>Soft Churn Rate</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{getMetricValue('soft_churn_rate',month.index)}} %
                </td>
            </tr>
            <tr>
                <th scope="row" class="dashboard-summary-table__left-title">Lifetime Value</th>
                <th colspan="12"></th>
            </tr>
            <tr>
                <td>Based on Hard Churn</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('hard_ltv',month.index))}}
                </td>
            </tr>
            <tr>
                <td>Based on Soft Churn</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('soft_ltv',month.index))}}
                </td>
            </tr>
            <tr>
                <th scope="row" class="dashboard-summary-table__left-title">Growth Rate</th>
                <th colspan="12"></th>
            </tr>
            <tr>
                <td>Trailing 12 month YOY Growth Rate (Without Setup Fees)</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('last_12_month_yoy_growth_rate_without_setup_fee',month.index))}}
                </td>
            </tr>
            <tr>
                <td>Trailing 12 month YOY Growth Rate (With Setup Fees)</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('last_12_month_yoy_growth_rate_with_setup_fee',month.index))}}
                </td>
            </tr>
            <tr>
                <td>Trailing 3 month YOY Growth Rate (Without Setup Fees)</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('last_3_month_yoy_growth_rate_without_setup_fee',month.index))}}
                </td>
            </tr>
            <tr>
                <td>Trailing 3 month YOY Growth Rate (With Setup Fees)</td>
                <td class="text-right" v-for="month in months" :key="month.index">
                    {{formatAmountDefault(getMetricValue('last_3_month_yoy_growth_rate_with_setup_fee',month.index))}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'SummaryContent',
  computed: {
    ...mapGetters({
      filterStartMonth: 'dashboard/getFilterStartMonth',
      filterEndMonth: 'dashboard/getFilterEndMonth',
      summary: 'dashboard/getSummary',
      months: 'dashboard/getMonths'
    })
  },
  methods: {
    getMetricValue (metric, date) {
      if (!(metric in this.summary && date in this.summary[metric])) {
        return 0
      }
      return this.summary[metric][date]
    }
  }
}
</script>

<style scoped>

</style>
