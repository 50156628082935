<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Monthly Revenue Breakdown</h3>
            <button class="btn btn-primary" @click="exportReport">
                Export CSV
            </button>
        </div>

        <list-filter @submit="filterSubmit"></list-filter>

        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="monthlyRevenues && monthlyRevenuesCount > 0">
                    <paginator :rowCount="monthlyRevenuesCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                    <list-table :records="monthlyRevenues"></list-table>
                    <paginator :rowCount="monthlyRevenuesCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import ListTable from './includes/ListTable'
import ListFilter from './includes/ListFilter'
import Paginator from '../includes/pagination/Paginator'

export default {
  name: 'MainPage',
  components: {
    ListFilter,
    ListTable,
    Paginator
  },
  computed: {
    ...mapGetters({
      monthlyRevenues: 'monthlyRevenueBreakdown/getMonthlyRevenues',
      monthlyRevenuesCount: 'monthlyRevenueBreakdown/getMonthlyRevenuesTotalCount',
      filterCurrency: 'monthlyRevenueBreakdown/getFilterCurrency',
      paginationCurrentPage: 'monthlyRevenueBreakdown/getPaginationCurrentPage',
      paginationPerPage: 'monthlyRevenueBreakdown/getPaginationPerPage'
    })
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapMutations({
      setPaginationActivePage: 'monthlyRevenueBreakdown/setPaginationActivePage',
      setPaginationPerPage: 'monthlyRevenueBreakdown/setPaginationPerPage'
    }),
    ...mapActions({
      loadData: 'monthlyRevenueBreakdown/loadData',
      downloadReport: 'fileExport/download'
    }),
    exportReport () {
      this.downloadReport({
        reportType: 'monthly-revenue-report',
        payload: {
          'currency': this.filterCurrency
        }
      })
    },
    filterSubmit () {
      this.loadData()
    },
    perPageChanged (perPage) {
      this.setPaginationPerPage(perPage)
      this.loadData()
    },
    pageChanged (page) {
      this.setPaginationActivePage(page)
      this.loadData()
    }
  }
}
</script>

<style scoped>

</style>
