<template>
    <div class="month-selector">
        <month-picker-input
            v-if="month && year"
            :clearable="true"
            :input-pre-filled="true"
            :default-month="month"
            :default-year="year"
            :max-date="max"
            @change="monthChanged"
        ></month-picker-input>
    </div>
</template>

<script>
import {MonthPickerInput} from 'vue-month-picker'
export default {
  name: 'MonthSelector',
  components: {
    MonthPickerInput
  },
  props: {
    maxMonth: {
      default: ''
    },
    defaultMonth: {
      default: ''
    },
    clearable: {
      default: false
    }
  },
  data () {
    return {
      month: '',
      year: '',
      max: ''
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    maxMonth (maxMonth) {
      this.max = new Date(maxMonth)
    }
  },
  methods: {
    init () {
      this.max = new Date(this.maxMonth)
      this.month = parseInt(this.$moment(this.defaultMonth).format('MM'))
      this.year = parseInt(this.$moment(this.defaultMonth).format('YYYY'))
    },
    monthChanged (data) {
      this.$emit('change', this.$moment()
        .month(data.month)
        .year(data.year)
        .startOf('month')
        .format('YYYY-MM-DD')
      )
    }
  }

}
</script>

<style scoped>

</style>
