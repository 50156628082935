<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Churn Type">
                <b-form-select v-model="filters.churnType.selected" :options="filters.churnType.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'ListFilter',
  data () {
    return {
      filters: {
        churnType: {
          selected: null,
          options: [
            {value: 'soft', text: 'Soft Churn'},
            {value: 'hard', text: 'Hard Churn'},
            {value: null, text: 'All Types'}
          ]
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      setFilterChurnType: 'churnedTrainers/setFilterChurnType'
    }),
    submitFilter () {
      this.setFilterChurnType(this.filters.churnType.selected)
      this.$emit('submit')
    }
  }
}
</script>

<style scoped>

</style>
