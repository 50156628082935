<template>
  <div class="app-wrapper" :class="collapseClass">
    <v-loading :active="loading > 0"
               :can-cancel="false"
               :is-full-page="true"></v-loading>

    <app-header v-if="auth"></app-header>
    <side-bar v-if="auth"></side-bar>
    <transition>
      <router-view></router-view>
    </transition>
    <commissions-rates-popup></commissions-rates-popup>
    <monthly-revenues-popup></monthly-revenues-popup>
  </div>
</template>

<script>
  import AppHeader from './components/includes/Header'
  import VLoading from 'vue-loading-overlay'
  import CommissionsRatesPopup from './components/includes/CommissionsRatesPopup'
  import MonthlyRevenuesPopup from './components/includes/MonthlyRevenuesPopup'
  import 'vue-loading-overlay/dist/vue-loading.css'
  import SideBar from './components/includes/SideBar'
  import router from './routes/index'
  import store from './store/index'
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'App',
    router,
    store,
    components: {
      SideBar,
      AppHeader,
      VLoading,
      CommissionsRatesPopup,
      MonthlyRevenuesPopup
    },
    computed: {
      ...mapGetters({
        sideBarCollapsed: 'sideBar/collapsed',
        user: 'getUser',
        loading: 'getLoading'
      }),
      auth () {
        return this.user && this.user.name
      },
      collapseClass () {
        if (!this.auth) {
          return
        }

        if (this.sideBarCollapsed) {
          return 'app-wrapper--collapsed'
        } else {
          return 'app-wrapper--expanded'
        }
      }
    },
    watch: {
      auth (value) {
        if (value) {
          this.loadStats()
        }
      }
    },
    mounted () {
      if (this.auth) {
        this.loadStats()
      }
    },
    methods: {
      ...mapActions({
        loadStats: 'loadStats'
      })
    }
  }
</script>

<style scoped>

</style>
