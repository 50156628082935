<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Active Trainers</h3>
        </div>
        <list-filter @submit="filterSubmit"></list-filter>
        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="activeTrainers && activeTrainers.length > 0">
                    <list-table :records="activeTrainers"></list-table>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ListTable from './includes/ListTable'
import ListFilter from './includes/ListFilter'

export default {
  name: 'MainPage',
  components: {
    ListFilter,
    ListTable
  },
  computed: {
    ...mapGetters({
      activeTrainers: 'activeTrainers/getActiveTrainers',
    })
  },
  mounted () {
    this.loadActiveTrainers()
  },
  methods: {
    ...mapActions({
      loadActiveTrainers: 'activeTrainers/loadData'
    }),
    filterSubmit () {
      this.loadActiveTrainers()
    }
  }
}
</script>

<style scoped>

</style>
