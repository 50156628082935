<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Start Month">
                <month-selector
                    :maxMonth="filterEndMonth"
                    :defaultMonth="filterStartMonth"
                    @change="setStartMonth"
                ></month-selector>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group label="End Month">
                <month-selector
                    :maxMonth="filters.monthRange.maxEnd"
                    :defaultMonth="filterEndMonth"
                    @change="setEndMonth"
                ></month-selector>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group label="Currency">
                <b-form-select v-model="filters.currency.selected" :options="filters.currency.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import MonthSelector from '../../includes/MonthSelector'
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'SummaryFilter',
  components: {
    MonthSelector
  },
  data () {
    return {
      filters: {
        monthRange: {
          selected: {
            startMonth: '',
            endMonth: ''
          },
          maxEnd: ''
        },
        currency: {
          selected: '',
          options: [
            {value: 'nzd', text: 'NZD'},
            {value: 'usd', text: 'USD'},
            {value: 'aud', text: 'AUD'},
            {value: 'cad', text: 'CAD'},
            {value: 'gbp', text: 'GBP'},
            {value: 'eur', text: 'EUR'},
            {value: 'sgd', text: 'SGD'},
            {value: 'nok', text: 'NOK'}
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      filterStartMonth: 'dashboard/getFilterStartMonth',
      filterEndMonth: 'dashboard/getFilterEndMonth',
      filterCurrency: 'dashboard/getFilterCurrency'
    })
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    ...mapMutations({
      setCurrency: 'dashboard/setFilterCurrency',
      setStartMonth: 'dashboard/setFilterStartMonth',
      setEndMonth: 'dashboard/setFilterEndMonth'
    }),
    submitFilter () {
      this.setCurrency(this.filters.currency.selected)
      this.$emit('submit')
    },
    initFilter () {
      this.filters.currency.selected = this.filterCurrency
      this.filters.monthRange.selected.startMonth = this.filterStartMonth
      this.filters.monthRange.selected.endMonth = this.filterEndMonth
      this.filters.monthRange.maxEnd = this.filterEndMonth
    }
  }
}
</script>

<style scoped>

</style>
