import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'
import TopProductsService from '../../services/TopProductsService'

Vue.use(Vuex)
Vue.use(VueMoment)
export default {
  namespaced: true,
  state: {
    filters: {
      month: {
        selected: Vue.moment().startOf('month').format('YYYY-MM-DD'),
        default: Vue.moment().startOf('month').format('YYYY-MM-DD'),
        max: Vue.moment().startOf('month').format('YYYY-MM-DD'),
        enabled: true
      },
      currency: {
        selected: 'nzd',
        options: [
          {value: 'nzd', text: 'NZD'},
          {value: 'usd', text: 'USD'},
          {value: 'aud', text: 'AUD'},
          {value: 'cad', text: 'CAD'},
          {value: 'gbp', text: 'GBP'},
          {value: 'eur', text: 'EUR'},
          {value: 'sgd', text: 'SGD'},
          {value: 'nok', text: 'NOK'}
        ]
      },
      searchQuery: ''
    },
    data: {
      page: '',
      per_page: 100,
      records: [],
      total_count: ''
    },
    pagination: {
      perPage: 50,
      activePage: 1
    }
  },
  mutations: {
    setFilters (state, filters) {
      state.filters = filters
    },
    setData (state, data) {
      state.data = data
    },
    setPaginationPerPage (state, data) {
      state.pagination.perPage = data
    },
    setPaginationActivePage (state, data) {
      state.pagination.activePage = data
    }
  },
  actions: {
    loadData ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let topProductsService = new TopProductsService()
        topProductsService
          .getAll({
            'page': state.pagination.activePage,
            'per_page': state.pagination.perPage,
            'month': state.filters.month.enabled ? state.filters.month.selected : '',
            'currency': state.filters.currency.selected,
            'search_query': state.filters.searchQuery
          })
          .then((res) => {
            commit('setData', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getFilters (state) {
      return state.filters
    },
    getRecords (state) {
      return state.data.records
    },
    getRecordsCount (state) {
      return state.data.total_count
    },
    getPaginationCurrentPage (state) {
      return state.pagination.activePage
    },
    getPaginationPerPage (state) {
      return state.pagination.perPage
    }
  }
}
