import APIService from './APIService'

export default class MetricsService {
  getAll (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('top-products', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
