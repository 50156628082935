import Vue from 'vue'
import Vuex from 'vuex'
import RevenuesService from '../../services/RevenuesService'
import VueMoment from 'vue-moment'

Vue.use(Vuex)
Vue.use(VueMoment)
export default {
  namespaced: true,
  state: {
    filters: {
      currency: 'nzd',
      query: ''
    },
    data: {
      page: '',
      per_page: 100,
      monthly_revenues: [],
      total_count: ''
    },
    pagination: {
      perPage: 50,
      activePage: 1
    }
  },
  mutations: {
    setFilterCurrency (state, currency) {
      state.filters.currency = currency
    },
    setFilterQuery (state, query) {
      state.filters.query = query
    },
    setMonthlyRevenues (state, data) {
      state.data = data
    },
    setPaginationPerPage (state, data) {
      state.pagination.perPage = data
    },
    setPaginationActivePage (state, data) {
      state.pagination.activePage = data
    }
  },
  actions: {
    loadData ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let splitService = new RevenuesService()
        splitService
          .getMonthlyBreakdown({
            'currency': state.filters.currency,
            'search_query': state.filters.query,
            'page': state.pagination.activePage,
            'per_page': state.pagination.perPage
          })
          .then((res) => {
            commit('setMonthlyRevenues', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getFilterCurrency (state) {
      return state.filters.currency
    },
    getFilterQuery (state) {
      return state.filters.query
    },
    getMonthlyRevenues (state) {
      return state.data.monthly_revenues
    },
    getMonthlyRevenuesTotalCount (state) {
      return state.data.total_count
    },
    getPaginationCurrentPage (state) {
      return state.pagination.activePage
    },
    getPaginationPerPage (state) {
      return state.pagination.perPage
    }
  }
}
