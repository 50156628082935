<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover lives-changed-table">
            <thead>
            <tr class="total-sec">
                <td colspan="3">
                    Total
                </td>
                <td class="text-right" v-for="(columnMonth, key) in columnMonths" :key="key">
                    {{addThousandSeparator(getLivesChangedValueByRange(
                        livesChangedTotals,
                        columnMonth.start_month,
                        columnMonth.end_month
                    ))}}
                </td>
            </tr>
            <tr class="table-title">
                <th scope="col" class="lives-changed-table__label-cell">Account ID</th>
                <th scope="col" class="lives-changed-table__label-cell">Trainer Name</th>
                <th scope="col" class="text-right lives-changed-table__value-cell">All Time</th>
                <th scope="col" class="text-right lives-changed-table__value-cell"
                    v-for="(columnMonth, key) in columnMonths" :key="key">
                    <span>
                        {{$moment(columnMonth.start_month).format('MMM')}}
                        to
                        {{$moment(columnMonth.end_month).format('MMM')}}
                    </span>
                    <br/>
                    <span>{{$moment(columnMonth.start_month).format('YYYY')}}</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="livesChangedRecord in livesChanged" :key="livesChangedRecord.account_id">
                <td>{{livesChangedRecord.account_id}}</td>
                <td>{{livesChangedRecord.trainer_name}}</td>
                <td class="text-right">
                    {{addThousandSeparator(getLivesChangedTotalByList(
                        livesChangedRecord.monthly_count
                    ))}}
                </td>
                <td class="text-right" v-for="(columnMonth, key) in columnMonths" :key="key">
                    {{addThousandSeparator(getLivesChangedValueByRange(
                        livesChangedRecord.monthly_count,
                        columnMonth.start_month,
                        columnMonth.end_month
                    ))}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'ListTable',
  props: {
    livesChanged: {},
    livesChangedTotals: {}
  },
  data () {
    return {
      columnMonths: [],
      totals: [],
      records: []
    }
  },
  watch: {
    livesChangedTotals () {
      this.setColumnMonths()
    }
  },
  mounted () {
    this.setColumnMonths()
  },
  methods: {
    setColumnMonths () {
      this.columnMonths = []
      let startMonth = Vue.moment('2015-01-01')
      let endMonth = Vue.moment()
      let rangeCount = 3
      let months = []
      let loopStartMonth = startMonth
      for (
        let m = Vue.moment(startMonth).add(rangeCount, 'months');
        m.diff(endMonth, 'months') <= 0;
        m.add(rangeCount, 'months')
      ) {
        let loopEndMonth = this.$moment(m.format('YYYY-MM-DD')).subtract(1, 'months')
        months.push({
          'start_month': loopStartMonth.format('YYYY-MM-DD'),
          'end_month': loopEndMonth.format('YYYY-MM-DD')
        })
        loopStartMonth = loopEndMonth.add(1, 'months')
      }
      months.reverse()
      this.columnMonths = months
    },
    getLivesChangedValueByRange (subscriptionCountList, startDate, endDate) {
      if (!(subscriptionCountList && subscriptionCountList instanceof Object)) {
        return 0
      }

      let start = Vue.moment(startDate)
      let end = Vue.moment(endDate)
      let subscriptionsCount = 0
      for (const [key, value] of Object.entries(subscriptionCountList)) {
        if (
          Vue.moment(key).isSameOrAfter(start) &&
          Vue.moment(key).isSameOrBefore(end)
        ) {
          subscriptionsCount += value
        }
      }
      return subscriptionsCount
    },
    getLivesChangedTotalByList (subscriptionCountList) {
      if (!(subscriptionCountList && subscriptionCountList instanceof Object)) {
        return 0
      }

      let subscriptionsCount = 0

      for (let date of Object.keys(subscriptionCountList)) {
        subscriptionsCount += subscriptionCountList[date]
      }

      return subscriptionsCount
    }
  }
}
</script>

<style scoped>

</style>
