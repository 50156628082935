import Vue from 'vue'
import Vuex from 'vuex'
import RevenuesService from '../../services/RevenuesService'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    visible: false,
    data: {
      monthly_revenues: []
    }
  },
  mutations: {
    hidePopup (state) {
      state.visible = false
    },
    showPopup (state) {
      state.visible = true
    },
    setData (state, data) {
      state.data = data
    }
  },
  actions: {
    loadRevenues ({commit}, {trainerId, currency}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, {root: true})
        let revenuesService = new RevenuesService()
        revenuesService
          .getMonthlyBreakdownByTrainer(
            trainerId,
            {
              'currency': currency
            }
          )
          .then((res) => {
            commit('setData', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, {root: true})
          })
      })
    }
  },
  getters: {
    visible (state) {
      return state.visible
    },
    revenues (state) {
      return state.data.monthly_revenues
    }
  }
}
