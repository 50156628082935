<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Top Products</h3>
        </div>

        <list-filter @submit="filterSubmit"></list-filter>

        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="records && records.length > 0">
                    <paginator :rowCount="recordsCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                    <list-table :records="records"></list-table>
                    <paginator :rowCount="recordsCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import ListTable from './includes/ListTable'
import ListFilter from './includes/ListFilter'
import Paginator from '../includes/pagination/Paginator'

export default {
  name: 'MainPage',
  components: {
    ListFilter,
    ListTable,
    Paginator
  },
  computed: {
    ...mapGetters({
      records: 'topProducts/getRecords',
      recordsCount: 'topProducts/getRecordsCount',
      paginationCurrentPage: 'topProducts/getPaginationCurrentPage',
      paginationPerPage: 'topProducts/getPaginationPerPage'
    })
  },
  mounted () {
    this.loadRecords()
  },
  methods: {
    ...mapMutations({
      setPaginationActivePage: 'topProducts/setPaginationActivePage',
      setPaginationPerPage: 'topProducts/setPaginationPerPage'
    }),
    ...mapActions({
      loadRecords: 'topProducts/loadData'
    }),
    filterSubmit () {
      this.loadRecords()
    },
    perPageChanged (perPage) {
      this.setPaginationPerPage(perPage)
      this.loadRecords()
    },
    pageChanged (page) {
      this.setPaginationActivePage(page)
      this.loadRecords()
    }
  }
}
</script>

<style scoped>

</style>
