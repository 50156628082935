import APIService from './APIService'

export default class MetricsService {
  getActiveTrainers (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('metrics/active-trainers', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getChurnedTrainers (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('metrics/churned-trainers', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getSummary (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('metrics/summary', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getLivesChanged (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('metrics/lives-changed', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getLivesChangedTotals (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('metrics/lives-changed/totals', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
