<template>
    <b-modal
        :visible="visible"
        title="Monthly Revenue Breakdown"
        @hide="hide"
        size="md"
        hide-footer
    >
        <table class="table table-bordered table-sm table-hover">
            <thead>

            <tr class="table-title">
                <td>Month</td>
                <td class="text-right">Amount</td>
                <td class="text-right">App Fee</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="revenue in revenues" :key="revenue.month">
                <td>{{$moment(revenue.month).format('MMM-YYYY')}}</td>
                <td class="text-right">{{formatAmountDefault(revenue.amount)}}</td>
                <td class="text-right">{{formatAmountDefault(revenue.app_fee)}}</td>
            </tr>
            </tbody>
        </table>
    </b-modal>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'MonthlyRevenuesPopup',
  computed: {
    ...mapGetters({
      visible: 'monthlyRevenuesPopup/visible',
      revenues: 'monthlyRevenuesPopup/revenues'
    })
  },
  methods: {
    ...mapMutations({
      hide: 'monthlyRevenuesPopup/hidePopup'
    })
  }
}
</script>

<style scoped>

</style>
