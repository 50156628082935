<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Currency">
                <b-form-select v-model="filters.currency.selected" :options="filters.currency.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group
                label="Keyword Search"
            >
                <b-form-input
                    v-model="filters.query"
                    type="text"
                    placeholder="Enter Trainer Name, Stripe ID, etc"
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: 'ListFilter',
  data () {
    return {
      filters: {
        currency: {
          selected: '',
          options: [
            {value: 'nzd', text: 'NZD'},
            {value: 'usd', text: 'USD'},
            {value: 'aud', text: 'AUD'},
            {value: 'cad', text: 'CAD'},
            {value: 'gbp', text: 'GBP'},
            {value: 'eur', text: 'EUR'},
            {value: 'sgd', text: 'SGD'},
            {value: 'nok', text: 'NOK'}
          ]
        },
        query: ''
      }
    }
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    ...mapMutations({
      setCurrency: 'monthlyRevenueBreakdown/setFilterCurrency',
      setQuery: 'monthlyRevenueBreakdown/setFilterQuery'
    }),
    submitFilter () {
      this.setCurrency(this.filters.currency.selected)
      this.setQuery(this.filters.query)
      this.$emit('submit')
    },
    initFilter () {
      this.filters.currency.selected = this.$store.getters['monthlyRevenueBreakdown/getFilterCurrency']
      this.filters.query = this.$store.getters['monthlyRevenueBreakdown/getFilterQuery']
    }
  }
}
</script>

<style scoped>

</style>
