<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Date Range">
                <date-range-picker
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                    singleDatePicker="range"
                    :timePicker="false"
                    :timePicker24Hour="true"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="false"
                    v-model="filters.date.selected"
                    :linkedCalendars="true"
                >
                    <template v-slot:input="picker" style="min-width: 350px;">
                        {{ formatDateDefault(picker.startDate) }} - {{ formatDateDefault(picker.endDate) }}
                    </template>
                </date-range-picker>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group label="Currency">
                <b-form-select v-model="filters.currency.selected" :options="filters.currency.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group
                label="Keyword Search"
            >
                <b-form-input
                    v-model="filters.query"
                    type="text"
                    placeholder="Enter Trainer Name, Stripe ID, etc"
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapMutations} from 'vuex'

export default {
  name: 'SplitsFilter',
  components: {
    DateRangePicker
  },
  data () {
    return {
      filters: {
        currency: {
          selected: '',
          options: [
            {value: 'nzd', text: 'NZD'},
            {value: 'usd', text: 'USD'},
            {value: 'aud', text: 'AUD'},
            {value: 'cad', text: 'CAD'},
            {value: 'gbp', text: 'GBP'},
            {value: 'eur', text: 'EUR'},
            {value: 'sgd', text: 'SGD'},
            {value: 'nok', text: 'NOK'}
          ]
        },
        date: {
          selected: {
            startDate: '',
            endDate: ''
          },
          options: {
            opens: 'center'
          }
        },
        query: ''
      }
    }
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    ...mapMutations({
      setCurrency: 'allTrainersSplits/setFilterCurrency',
      setDateRange: 'allTrainersSplits/setFilterDateRange',
      setQuery: 'allTrainersSplits/setFilterQuery',

      // single trainer filter set
      setSingleViewCurrency: 'singleTrainerSplits/setFilterCurrency',
      setSingleViewDateRange: 'singleTrainerSplits/setFilterDateRange'
    }),
    submitFilter () {
      this.setCurrency(this.filters.currency.selected)
      this.setDateRange(this.filters.date.selected)
      this.setQuery(this.filters.query)

      this.setSingleViewCurrency(this.filters.currency.selected)
      this.setSingleViewDateRange(this.filters.date.selected)

      this.$emit('submit')
    },
    initFilter () {
      this.filters.currency.selected = this.$store.getters['allTrainersSplits/getFilterCurrency']
      this.filters.date.selected.startDate = this.$store.getters['allTrainersSplits/getFilterDateRange'].startDate
      this.filters.date.selected.endDate = this.$store.getters['allTrainersSplits/getFilterDateRange'].endDate
      this.filters.query = this.$store.getters['allTrainersSplits/getFilterQuery']
    }
  }
}
</script>

<style scoped>

</style>
