<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Overview Report</h3>
        </div>

        <splits-filter @submit="filterSubmit"></splits-filter>
        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="splitsCount && splitsCount > 0">
                    <paginator :rowCount="splitsCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                    <splits-table :splits="splits"
                                  :splits-totals="splitsTotals"
                                  @splitTrainerClicked="viewDetails"
                                  @splitSharesClicked="loadCommissionRatesPopup"
                    ></splits-table>
                    <paginator :rowCount="splitsCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SplitsFilter from './includes/SplitsFilter'
import {mapGetters, mapMutations, mapActions} from 'vuex'
import RevenuesService from '../../services/RevenuesService'
import Paginator from '../includes/pagination/Paginator'
import SplitsTable from './includes/SplitsTable'

export default {
  name: 'MainPage',
  components: {
    SplitsFilter,
    SplitsTable,
    Paginator
  },
  computed: {
    ...mapGetters({
      splitsTotals: 'allTrainersSplits/getSplitsTotals',
      splitData: 'allTrainersSplits/getSplits',
      splits: 'allTrainersSplits/getSplits',
      splitsCount: 'allTrainersSplits/getSplitsTotalCount',
      paginationCurrentPage: 'allTrainersSplits/getPaginationCurrentPage',
      paginationPerPage: 'allTrainersSplits/getPaginationPerPage'
    })
  },
  mounted () {
    this.loadSplits()
    this.loadSplitTotals()
  },
  methods: {
    ...mapMutations({
      showCommissionRatesPopup: 'commissionRatesPopup/showPopup',
      setCommissionRatesPopupRates: 'commissionRatesPopup/setRates',
      setCommissionRatesPopupTrainer: 'commissionRatesPopup/setTrainer',
      setPaginationActivePage: 'allTrainersSplits/setPaginationActivePage',
      setPaginationPerPage: 'allTrainersSplits/setPaginationPerPage',
      setFilterSubmit: 'allTrainersSplits/setFilterSubmit'
    }),
    ...mapActions({
      loadSplits: 'allTrainersSplits/loadSplits',
      loadSplitTotals: 'allTrainersSplits/loadSplitTotals'
    }),
    filterSubmit () {
      this.setPaginationActivePage(1)
      this.loadSplits()
      this.loadSplitTotals()
    },
    loadCommissionRatesPopup (trainerId, trainerName) {
      // TODO: pass trainer object (refactor point)
      this.showLoading()
      let splitService = new RevenuesService()
      splitService.getRates(trainerId)
        .then((res) => {
          this.setCommissionRatesPopupTrainer({
            name: trainerName
          })
          this.setCommissionRatesPopupRates(res.data.rates)
          this.showCommissionRatesPopup()
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    viewDetails (trainerId) {
      this.$router.push({
        path: 'view',
        query: {
          trainerId: trainerId
        }
      })
    },
    perPageChanged (perPage) {
      this.setPaginationPerPage(perPage)
      this.loadSplits()
    },
    pageChanged (page) {
      this.setPaginationActivePage(page)
      this.loadSplits()
    }
  }
}
</script>

<style scoped>

</style>
