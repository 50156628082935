<template>
    <div>
        <h6 class="text-right">Showing results {{startRecord}} - {{endRecord}} from {{totalRows}} records</h6>
        <b-pagination v-model="page" pills :total-rows="totalRows" :per-page="perPage" align="right" @change="paginationChange"></b-pagination>
    </div>
</template>

<script>
export default {
  name: 'PageSelector',
  props: {
    perPage: {
      default: ''
    },
    totalRows: {
      default: ''
    },
    activePage: {
      default: ''
    }
  },
  data () {
    return {
      page: 1,
      startRecord: '',
      endRecord: ''
    }
  },
  watch: {
    activePage (val) {
      this.page = val
      this.setPageData()
    },
    perPage () {
      this.setPageData()
    }
  },
  mounted () {
    this.setPageData()
  },
  methods: {
    paginationChange (page) {
      this.$emit('pageChanged', page)
      this.setPageData()
    },
    setPageData () {
      this.startRecord = (this.page * this.perPage) - this.perPage + 1
      this.endRecord = this.perPage * this.page
      if (this.endRecord > this.totalRows) {
        this.endRecord = this.totalRows
      }
    }
  }
}
</script>

<style scoped>

</style>
