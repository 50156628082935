import Vue from 'vue'
import Vuex from 'vuex'
import MetricsService from '../../services/MetricsService'
import VueMoment from 'vue-moment'

Vue.use(Vuex)
Vue.use(VueMoment)

export default {
  namespaced: true,
  state: {
    filters: {
      currency: 'nzd',
      monthRange: {
        startMonth: Vue.moment().subtract(13, 'months').startOf('month').format('YYYY-MM-DD'),
        endMonth: Vue.moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      },
      metrics: [
        'active_trainers_count',
        'soft_active_trainers_count',
        'hard_active_trainers_count',
        'hard_churn_rate',
        'soft_churn_rate',
        'annualized_revenue',
        'last_12_month_yoy_growth_rate_without_setup_fee',
        'last_12_month_yoy_growth_rate_with_setup_fee',
        'last_3_month_yoy_growth_rate_without_setup_fee',
        'last_3_month_yoy_growth_rate_with_setup_fee',
        'hard_ltv',
        'soft_ltv',
        '12_months_mrr'
      ]
    },
    data: {
      summary: []
    },
    months: []
  },
  mutations: {
    setFilterCurrency (state, currency) {
      state.filters.currency = currency
    },
    setData (state, data) {
      state.data = data
    },
    setFilterEndMonth (state, endMonth) {
      state.filters.monthRange.endMonth = Vue.moment(endMonth).format('YYYY-MM-01')
    },
    setFilterStartMonth (state, startMonth) {
      state.filters.monthRange.startMonth = Vue.moment(startMonth).format('YYYY-MM-01')
    },
    setMonths (state, months) {
      state.months = months
    }
  },
  actions: {
    loadData ({dispatch, commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, {root: true})
        let metricsService = new MetricsService()
        metricsService
          .getSummary({
            'currency': state.filters.currency,
            'frequency': 'monthly',
            'start_at': state.filters.monthRange.startMonth,
            'end_at': state.filters.monthRange.endMonth,
            'metrics': state.filters.metrics
          })
          .then((res) => {
            commit('setData', res.data)
            dispatch('refreshMonths')
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, {root: true})
          })
      })
    },
    refreshMonths ({commit, state}) {
      let startMonth = Vue.moment(state.filters.monthRange.startMonth)
      let endMonth = Vue.moment(state.filters.monthRange.endMonth)
      let months = []
      for (let m = Vue.moment(endMonth); startMonth.diff(m, 'months') <= 0; m.subtract(1, 'months')) {
        months.push({
          'index': m.format('YYYY-MM-DD'),
          'label': m.format('MMM-YYYY')
        })
      }
      commit('setMonths', months)
    }
  },
  getters: {
    getFilterCurrency (state) {
      return state.filters.currency
    },
    getFilterStartMonth (state) {
      return state.filters.monthRange.startMonth
    },
    getFilterEndMonth (state) {
      return state.filters.monthRange.endMonth
    },
    getSummary (state) {
      return state.data.summary
    },
    getMonths (state) {
      return state.months
    }
  }
}
