import APIService from './APIService'

export default class StatsService {
  getStats (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('stats', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
