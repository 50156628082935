<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover lives-changed-table">
            <thead>
            <tr class="total-sec">
                <td colspan="4">
                    Total
                </td>
                <td class="text-right" v-for="(columnDate, key) in columnDates" :key="key">
                    {{addThousandSeparator(getLivesChangedValueByDate(
                    livesChangedTotals,
                    columnDate
                    ))}}
                </td>
            </tr>
            <tr class="table-title">
                <th scope="col" class="lives-changed-table__label-cell">#</th>
                <th scope="col" class="lives-changed-table__label-cell">Account ID</th>
                <th scope="col" class="lives-changed-table__label-cell">Trainer Name</th>
                <th scope="col" class="text-right lives-changed-table__value-cell">Cumulative</th>
                <th scope="col" class="text-right lives-changed-table__value-cell"
                    v-for="(columnDate, key) in columnDates" :key="key">
                    <span>
                        {{$moment(columnDate).format('DD MMM')}}
                    </span>
                    <br/>
                    <span>{{$moment(columnDate).format('YYYY')}}</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(livesChangedRecord, key) in livesChanged" :key="livesChangedRecord.account_id">
                <td>{{((parseInt(key) + 1) + ((currentPage - 1) * perPage))}}</td>
                <td>{{livesChangedRecord.account_id}}</td>
                <td>{{livesChangedRecord.trainer_name}}</td>
                <td class="text-right">
                    {{addThousandSeparator(getLivesChangedTotalByList(
                    livesChangedRecord.daily_count
                    ))}}
                </td>
                <td class="text-right" v-for="(columnDate, key) in columnDates" :key="key">
                    {{addThousandSeparator(getLivesChangedValueByDate(
                    livesChangedRecord.daily_count,
                    columnDate
                    ))}}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'

export default {
  name: 'ListTable',
  props: {
    livesChanged: {},
    livesChangedTotals: {}
  },
  data () {
    return {
      columnMonths: [],
      columnDates: [],
      totals: [],
      records: []
    }
  },
  computed: {
    ...mapGetters({
      filterDateRange: 'livesChangedDaily/getFilterDateRange',
      currentPage: 'livesChangedDaily/getPaginationCurrentPage',
      perPage: 'livesChangedDaily/getPaginationPerPage'
    })
  },
  watch: {
    livesChangedTotals () {
      this.setColumnDates()
    },
    filterDateRange: {
      handler: function () {
        this.setColumnDates()
      },
      deep: true
    }
  },
  mounted () {
    this.setColumnDates()
  },
  methods: {
    setColumnDates () {
      this.columnDates = []
      let startDate = Vue.moment(this.filterDateRange.startDate)
      let endDate = Vue.moment(this.filterDateRange.endDate)

      let dates = []
      for (let m = Vue.moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        dates.push(m.format('YYYY-MM-DD'))
      }

      this.columnDates = dates
    },
    getLivesChangedValueByDate (subscriptionCountList, columnDate) {
      if (!(subscriptionCountList && subscriptionCountList instanceof Object)) {
        return 0
      }

      let totals = Object.assign([], subscriptionCountList)

      return totals[columnDate] ? totals[columnDate] : 0
    },
    getLivesChangedTotalByList (subscriptionCountList) {
      if (!(subscriptionCountList && subscriptionCountList instanceof Object)) {
        return 0
      }

      let subscriptionsCount = 0

      for (let date of Object.keys(subscriptionCountList)) {
        subscriptionsCount += subscriptionCountList[date]
      }

      return subscriptionsCount
    }
  }
}
</script>

<style scoped>

</style>
