import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import SignIn from '../components/auth/SignIn'
import SingleTrainerSplits from '../components/single-trainer-splits/MainPage'
import AllTrainersSplits from '../components/all-trainers-splits/MainPage'
import ActiveTrainers from '../components/active-trainers/MainPage'
import AuthService from '../services/AuthService'
import Dashboard from '../components/dashboard/MainPage'
import ChurnedTrainers from '../components/churned-trainers/MainPage'
import LivesChanged from '../components/lives-changed/MainPage'
import LivesChangedDaily from '../components/lives-changed-daily/MainPage'
import MonthlyRevenue from '../components/monthly-revenue/MainPage'
import TopProducts from '../components/top-products/MainPage'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: SignIn,
      props: true,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/',
      name: 'Dashboard',
      component: Dashboard,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/overview',
      name: 'AllTrainersSplits',
      component: AllTrainersSplits,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/view',
      name: 'SingleTrainerSplits',
      component: SingleTrainerSplits,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/active-trainers',
      name: 'ActiveTrainers',
      component: ActiveTrainers,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/churned-trainers',
      name: 'ChurnedTrainers',
      component: ChurnedTrainers,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lives-changed',
      name: 'LivesChanged',
      component: LivesChanged,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/lives-changed-daily',
      name: 'LivesChangedDaily',
      component: LivesChangedDaily,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/monthly-revenue',
      name: 'MonthlyRevenue',
      component: MonthlyRevenue,
      props: true,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/top-products',
      name: 'TopProducts',
      component: TopProducts,
      props: true,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (typeof to.meta !== 'undefined' && to.meta.requiresAuth && !store.getters.getAccessToken) {
    let authService = new AuthService()
    authService.syncInstanceAuth()

    if (!store.getters.getAccessToken) {
      return next({path: '/login'})
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
