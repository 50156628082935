<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover">
            <thead>
            <tr class="table-title">
                <th scope="col" width="10%">Trainer ID</th>
                <th scope="col" width="30%">Trainer Name</th>
                <th scope="col" width="20%">Stripe ID</th>
                <th scope="col" width="20%">Churned Month</th>
                <th scope="col" width="20%">Churn Type</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="record in records" :key="record.id">
                <td>{{record.id}}</td>
                <td>{{record.name}}</td>
                <td>{{record.stripe_id}}</td>
                <td>{{record.churned_month}}</td>
                <td>{{record.churn_type}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'ListTable',
  props: {
    records: {}
  }
}
</script>

<style scoped>

</style>
