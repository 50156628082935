import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'
import LivesChangedDailyService from '../../services/LivesChangedDailyService'
import ExportService from '../../services/ExportService'

Vue.use(Vuex)
Vue.use(VueMoment)
export default {
  namespaced: true,
  state: {
    filters: {
      dateRange: {
        startDate: '2022-06-21',
        endDate: '2022-09-29'
      }
    },
    data: {
      page: '',
      per_page: 100,
      lives_changed: [],
      total_count: ''
    },
    totals: {},
    pagination: {
      perPage: 15,
      activePage: 1
    },
    fileToken: ''
  },
  mutations: {
    setFilterDateRange (state, range) {
      state.filters.dateRange.startDate = Vue.moment(range.startDate).format('YYYY-MM-DD')
      state.filters.dateRange.endDate = Vue.moment(range.endDate).format('YYYY-MM-DD')
    },
    setData (state, data) {
      state.data = data
    },
    setTotals (state, totals) {
      state.totals = totals
    },
    setPaginationPerPage (state, data) {
      state.pagination.perPage = data
    },
    setPaginationActivePage (state, data) {
      state.pagination.activePage = data
    },
    setFileToken (state, token) {
      state.fileToken = token
    }
  },
  actions: {
    loadData ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let livesChangedDailyService = new LivesChangedDailyService()
        livesChangedDailyService
          .getAll({
            'start_date': state.filters.dateRange.startDate,
            'end_date': state.filters.dateRange.endDate,
            'page': state.pagination.activePage,
            'per_page': state.pagination.perPage
          })
          .then((res) => {
            commit('setData', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    },
    loadTotals ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let livesChangedDailyService = new LivesChangedDailyService()
        livesChangedDailyService
          .getTotals({
            'start_date': state.filters.dateRange.startDate,
            'end_date': state.filters.dateRange.endDate
          })
          .then((res) => {
            commit('setTotals', res.data.totals)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    },
    downloadReport ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let livesChangedDailyService = new LivesChangedDailyService()
        livesChangedDailyService
          .getExportToken({
            'start_date': state.filters.dateRange.startDate,
            'end_date': state.filters.dateRange.endDate
          })
          .then((res) => {
            commit('setFileToken', res.data.file_token)
            let exportService = new ExportService()
            exportService.download(state.fileToken)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getFilterDateRange (state) {
      return state.filters.dateRange
    },
    getLivesChanged (state) {
      return state.data.lives_changed
    },
    getLivesChangedTotals (state) {
      return state.totals
    },
    getLivesChangedTotalCount (state) {
      return state.data.total_count
    },
    getPaginationCurrentPage (state) {
      return state.pagination.activePage
    },
    getPaginationPerPage (state) {
      return state.pagination.perPage
    },
    getFileToken (state) {
      return state.fileToken
    }
  }
}
