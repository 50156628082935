import Vue from 'vue'
import Vuex from 'vuex'
import MetricsService from '../../services/MetricsService'
import VueMoment from 'vue-moment'

Vue.use(Vuex)
Vue.use(VueMoment)
export default {
  namespaced: true,
  state: {
    filters: {
      churnType: 'all',
      month: Vue.moment().startOf('month').format('YYYY-MM-DD')
    },
    data: {
      active_trainers: []
    }
  },
  mutations: {
    setFilterMonth (state, month) {
      state.filters.month = month
    },
    setFilterChurnType (state, churnType) {
      state.filters.churnType = churnType
    },
    setData (state, data) {
      state.data = data
    }
  },
  actions: {
    loadData ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let metricsService = new MetricsService()
        metricsService
          .getActiveTrainers({
            'month': state.filters.month,
            'churn_type': state.filters.churnType
          })
          .then((res) => {
            commit('setData', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getFilterMonth (state) {
      return state.filters.month
    },
    getFilterChurnType (state) {
      return state.filters.churnType
    },
    getActiveTrainers (state) {
      return state.data.active_trainers
    }
  }
}
