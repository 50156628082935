<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Month">
                <month-selector
                    v-if="filters.month.default && filters.month.enabled"
                    :clearable="true"
                    :maxMonth="filters.month.max"
                    :defaultMonth="filters.month.selected ? filters.month.selected : filters.month.default"
                    @change="setMonth"
                ></month-selector>
                <b-form-checkbox
                    v-model="filters.month.enabled"
                    :value="false"
                    :unchecked-value="true"
                >
                    Disable Month
                </b-form-checkbox>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group label="Currency">
                <b-form-select v-model="filters.currency.selected" :options="filters.currency.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group
                label="Keyword Search"
            >
                <b-form-input
                    v-model="filters.searchQuery"
                    type="text"
                    placeholder="Enter Trainer Name, Stripe ID, etc"
                ></b-form-input>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import MonthSelector from '../../includes/MonthSelector'

export default {
  name: 'ListFilter',
  props: {
    month: {}
  },
  components: {
    MonthSelector
  },
  data () {
    return {
      status: '',
      filters: {
        month: {
          selected: '',
          max: '',
          default: '',
          enabled: true
        },
        currency: {
          selected: '',
          options: []
        },
        searchQuery: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      getFilters: 'topProducts/getFilters'
    })
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    ...mapMutations({
      setFilters: 'topProducts/setFilters'
    }),
    submitFilter () {
      this.setFilters(this.filters)
      this.$emit('submit')
    },
    setMonth (month) {
      this.filters.month.selected = month
    },
    initFilter () {
      this.filters = this.getFilters
    }
  }
}
</script>

<style scoped>

</style>
