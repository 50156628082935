import Vue from 'vue'
import Vuex from 'vuex'
import RevenuesService from '../../services/RevenuesService'
import VueMoment from 'vue-moment'

Vue.use(Vuex)
Vue.use(VueMoment)
export default {
  namespaced: true,
  state: {
    filters: {
      currency: 'nzd',
      dateRange: {
        startDate: Vue.moment().startOf('month').format('YYYY-MM-DD'),
        endDate: Vue.moment().endOf('month').format('YYYY-MM-DD')
      },
      query: ''
    },
    data: {
      page: '',
      per_page: 100,
      splits: [],
      total_count: ''
    },
    totals: {},
    pagination: {
      perPage: 50,
      activePage: 1
    }
  },
  mutations: {
    setFilterCurrency (state, currency) {
      state.filters.currency = currency
    },
    setFilterDateRange (state, range) {
      state.filters.dateRange.startDate = Vue.moment(range.startDate).format('YYYY-MM-DD')
      state.filters.dateRange.endDate = Vue.moment(range.endDate).format('YYYY-MM-DD')
    },
    setFilterQuery (state, query) {
      state.filters.query = query
    },
    setSplitsTotals (state, totals) {
      state.totals = totals
    },
    setSplits (state, data) {
      state.data = data
    },
    setPaginationPerPage (state, data) {
      state.pagination.perPage = data
    },
    setPaginationActivePage (state, data) {
      state.pagination.activePage = data
    }
  },
  actions: {
    loadSplits ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let splitService = new RevenuesService()
        splitService
          .getGrouped({
            'currency': state.filters.currency,
            'search_query': state.filters.query,
            'page': state.pagination.activePage,
            'per_page': state.pagination.perPage,
            'start_date': state.filters.dateRange.startDate,
            'end_date': state.filters.dateRange.endDate
          })
          .then((res) => {
            commit('setSplits', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    },
    loadSplitTotals ({commit, state}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let splitService = new RevenuesService()
        splitService
          .getGroupedTotals({
            'currency': state.filters.currency,
            'start_date': state.filters.dateRange.startDate,
            'end_date': state.filters.dateRange.endDate,
            'search_query': state.filters.query
          })
          .then((res) => {
            commit('setSplitsTotals', res.data.totals)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getFilterCurrency (state) {
      return state.filters.currency
    },
    getFilterDateRange (state) {
      return state.filters.dateRange
    },
    getFilterQuery (state) {
      return state.filters.query
    },
    getSplitsTotals (state) {
      return state.totals
    },
    getSplits (state) {
      return state.data.splits
    },
    getSplitsTotalCount (state) {
      return state.data.total_count
    },
    getPaginationCurrentPage (state) {
      return state.pagination.activePage
    },
    getPaginationPerPage (state) {
      return state.pagination.perPage
    }
  }
}
