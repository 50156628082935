<template>
<div>
    <table class="table table-bordered table-sm">
        <thead>
        <tr class="total-sec">
            <td colspan="4">
                Total
            </td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.trainer_received)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.stripe_fee)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.app_fee)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.app_fee - splitsTotals.macro_total)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.macro_total)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.macro_platform)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.macro_support)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.macro_advertising)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.macro_marketing)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.macro_other)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_total)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_platform)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_support)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_advertising)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_marketing)}}</td>
            <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_other)}}</td>
        </tr>
        <tr class="table-title">
            <th scope="col">Charge ID</th>
            <th scope="col">Trainer Name</th>
            <th scope="col">Transaction Date</th>
            <th scope="col">Subscription Date</th>
            <th scope="col" class="text-right">Trainer Received</th>
            <th scope="col" class="text-right">Stripe Fee</th>
            <th scope="col" class="text-right">App Fee</th>
            <th scope="col" class="text-right">Variance</th>
            <th scope="col" class="text-right">Macro Total</th>
            <th scope="col" class="text-right">Macro Platform</th>
            <th scope="col" class="text-right">Macro Support</th>
            <th scope="col" class="text-right">Macro Advertising</th>
            <th scope="col" class="text-right">Macro Marketing</th>
            <th scope="col" class="text-right">Macro Other</th>
            <th scope="col" class="text-right">Reseller Total</th>
            <th scope="col" class="text-right">Reseller Platform</th>
            <th scope="col" class="text-right">Reseller Support</th>
            <th scope="col" class="text-right">Reseller Advertising</th>
            <th scope="col" class="text-right">Reseller Marketing</th>
            <th scope="col" class="text-right">Reseller Other</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="split in splits" :key="split.id">
            <th scope="row">{{split.charge}}</th>
            <td>{{split.trainer_name}}</td>
            <td>{{split.transaction_created}}</td>
            <td>{{split.subscription_created}}</td>
            <td class="text-right">{{formatAmountDefault(split.trainer_received)}}</td>
            <td class="text-right">{{formatAmountDefault(split.stripe_fee)}}</td>
            <td class="text-right">{{formatAmountDefault(split.app_fee)}}</td>
            <td class="text-right">{{formatAmountDefault(split.app_fee - split.macro_total)}}</td>
            <td class="text-right">{{formatAmountDefault(split.macro_total)}}</td>
            <td class="text-right">{{formatAmountDefault(split.macro_platform)}}</td>
            <td class="text-right">{{formatAmountDefault(split.macro_support)}}</td>
            <td class="text-right">{{formatAmountDefault(split.macro_advertising)}}</td>
            <td class="text-right">{{formatAmountDefault(split.macro_marketing)}}</td>
            <td class="text-right">{{formatAmountDefault(split.macro_other)}}</td>
            <td class="text-right">{{formatAmountDefault(split.reseller_total)}}</td>
            <td class="text-right">{{formatAmountDefault(split.reseller_platform)}}</td>
            <td class="text-right">{{formatAmountDefault(split.reseller_support)}}</td>
            <td class="text-right">{{formatAmountDefault(split.reseller_advertising)}}</td>
            <td class="text-right">{{formatAmountDefault(split.reseller_marketing)}}</td>
            <td class="text-right">{{formatAmountDefault(split.reseller_other)}}</td>
        </tr>
        </tbody>
    </table>
</div>
</template>

<script>
export default {
  name: 'SplitsTable',
  props: {
    splits: {},
    splitsTotals: {}
  }
}
</script>

<style scoped>

</style>
