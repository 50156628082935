<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3>Churned Trainers</h3>
        </div>
        <list-filter @submit="filterSubmit"></list-filter>
        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="churnedTrainers && churnedTrainers.length > 0">
                    <list-table :records="churnedTrainers"></list-table>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListTable from './includes/ListTable'
import ListFilter from './includes/ListFilter'
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'MainPage',
  components: {
    ListTable,
    ListFilter
  },
  computed: {
    ...mapGetters({
      churnedTrainers: 'churnedTrainers/getChurnedTrainers'
    })
  },
  mounted () {
    this.loadChurnedTrainers()
  },
  methods: {
    ...mapActions({
      loadChurnedTrainers: 'churnedTrainers/loadData'
    }),
    filterSubmit () {
      this.loadChurnedTrainers()
    }
  }
}
</script>

<style scoped>

</style>
