import Vue from 'vue'
import App from './App.vue'

import VueMoment from 'vue-moment'
Vue.use(VueMoment)

import axios from 'axios'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faChartPie, faArrowsAltH} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faChartPie)
library.add(faArrowsAltH)

import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import VueSidebarMenu from 'vue-sidebar-menu'
Vue.use(VueSidebarMenu)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import globalMixin from './mixins/globalMixin'
Vue.mixin(globalMixin)

import { MonthPicker, MonthPickerInput } from 'vue-month-picker'
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

import ExceptionHandler from './services/ExceptionHandler'
import '@/assets/scss/global.css'

Vue.config.productionTip = false

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  let exceptionHandler = new ExceptionHandler()
  exceptionHandler.handleApiError(error.response)
  return Promise.reject(error)
})

new Vue({
  render: h => h(App),
}).$mount('#app')
