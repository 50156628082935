import APIService from './APIService'

export default class ExportService {
  getFileToken (reportType, payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get(`reports/${reportType}`, payload)
        .then(res => {
          resolve(res)
        }).catch(err => {
          reject(err)
        })
    })
  }

  download (fileToken) {
    location.href = `${process.env.VUE_APP_API_HOST}/reports/download/${fileToken}`
  }
}
