<template>
    <main class="mr-auto ml-auto form-signin">
        <b-form class="mx-auto" @submit.prevent="signIn">
            <img class="mb-4" src="../../assets/images/logo.png" alt="" width="100%">
            <b-form-group
                label="Email:"
            >
                <b-form-input
                    v-model="payload.email"
                    type="email"
                    placeholder="Email"
                    v-bind:class="{ 'is-invalid': errors.email }"
                ></b-form-input>
                <div class="invalid-feedback" v-if="errors.email">
                    {{errors.email[0]}}
                </div>

            </b-form-group>

            <b-form-group label="Password:">
                <b-form-input
                    type="password"
                    v-model="payload.password"
                    placeholder="Password"
                    v-bind:class="{ 'is-invalid': errors.password }"
                ></b-form-input>
                <div class="invalid-feedback" v-if="errors.password">
                    {{errors.password[0]}}
                </div>
            </b-form-group>
            <b-button type="submit" variant="primary" class="btn-block float-right">
                <span v-if="submitting">Please wait</span>
                <span v-else>Log In</span>
                <b-spinner small v-if="submitting"></b-spinner>
            </b-button>
        </b-form>
    </main>
</template>

<script>
import AuthService from '../../services/AuthService'
import {mapMutations} from 'vuex'

export default {
  name: 'SignIn',
  data () {
    return {
      payload: {
        email: '',
        password: ''
      },
      errors: {
        email: '',
        password: ''
      },
      submitting: false
    }
  },
  methods: {
    ...mapMutations({
      setGlobalErrorHandling: 'setGlobalErrorHandling'
    }),
    signIn () {
      this.submitting = true
      this.setGlobalErrorHandling(false)
      let authService = new AuthService()
      authService
        .signIn(this.payload)
        .then(() => {
          this.$router.push('/')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
        })
        .finally(() => {
          this.submitting = false
          this.setGlobalErrorHandling(true)
        })
    }
  }
}
</script>

<style scoped>

</style>
