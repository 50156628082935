import Vue from 'vue'
import Vuex from 'vuex'
import ExportService from '../../services/ExportService'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    fileToken: ''
  },
  mutations: {
    setFileToken (state, token) {
      state.fileToken = token
    }
  },
  actions: {
    download ({commit, state}, data) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true, { root: true })
        let exportService = new ExportService()
        exportService
          .getFileToken(data.reportType, data.payload)
          .then((res) => {
            commit('setFileToken', res.data.file_token)
            exportService.download(state.fileToken)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false, { root: true })
          })
      })
    }
  },
  getters: {
    getFileToken (state) {
      return state.fileToken
    }
  }
}
