import APIService from './APIService'

export default class RevenuesService {
  getGrouped (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getGroupedTotals (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers/totals', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getTrainerWise (trainerId, payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers/' + trainerId, payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getTrainerWiseTotals (trainerId, payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers/' + trainerId + '/totals', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getRates (trainerId) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers/' + trainerId + '/rates').then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getMonthlyBreakdownByTrainer (trainerId, payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers/' + trainerId + '/monthly-revenues', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getMonthlyBreakdown (payload = []) {
    return new Promise((resolve, reject) => {
      let api = new APIService()
      api.get('splits/trainers/monthly-revenues', payload).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
