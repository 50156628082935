<template>
    <div>
        <sidebar-menu
            :menu="items"
            :collapsed="options.collapsed"
            :showOneChild="options.showOneChild"
            :width="options.expandedWidth"
            @toggle-collapse="setCollapsed">
        </sidebar-menu>
    </div>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      collapsed: 'sideBar/collapsed',
      items: 'sideBar/items',
      options: 'sideBar/options'
    })
  },
  methods: {
    ...mapMutations({
      setCollapsed: 'sideBar/setCollapsed'
    })
  }
}
</script>

<style scoped>

</style>
