<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Date Range">
                <date-range-picker
                    ref="picker"
                    opens="center"
                    :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                    singleDatePicker="range"
                    :timePicker="false"
                    :timePicker24Hour="true"
                    :showWeekNumbers="false"
                    :showDropdowns="false"
                    :autoApply="false"
                    v-model="filters.date.selected"
                    :linkedCalendars="true"
                >
                    <template v-slot:input="picker" style="min-width: 350px;">
                        {{ formatDateDefault(picker.startDate) }} - {{ formatDateDefault(picker.endDate) }}
                    </template>
                </date-range-picker>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapMutations} from 'vuex'

export default {
  name: 'ListFilter',
  components: {
    DateRangePicker
  },
  data () {
    return {
      filters: {
        date: {
          selected: {
            startDate: '',
            endDate: ''
          },
          options: {
            opens: 'center'
          }
        }
      }
    }
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    ...mapMutations({
      setDateRange: 'livesChangedDaily/setFilterDateRange'
    }),
    submitFilter () {
      this.setDateRange(this.filters.date.selected)
      this.$emit('submit')
    },
    initFilter () {
      this.filters.date.selected.startDate = this.$store.getters['livesChangedDaily/getFilterDateRange'].startDate
      this.filters.date.selected.endDate = this.$store.getters['livesChangedDaily/getFilterDateRange'].endDate
    }
  }
}
</script>

<style scoped>

</style>
