<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover">
            <thead>
            <tr class="table-title">
                <th scope="col">Month</th>
                <th scope="col">Account ID</th>
                <th scope="col">Trainer Name</th>
                <th scope="col">Trainer Received</th>
                <th scope="col">Stripe Fee</th>
                <th scope="col">App Fee</th>
                <th scope="col">Macro Total</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="record in records" :key="record.id">
                <td>{{$moment(record.month).format('MMM-YYYY')}}</td>
                <td>{{record.stripe_id}}</td>
                <td>{{record.trainer_name}}</td>
                <td>{{record.trainer_received}}</td>
                <td>{{record.stripe_fee}}</td>
                <td>{{record.app_fee}}</td>
                <td>{{record.macro_total}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'ListTable',
  props: {
    records: {}
  }
}
</script>

<style scoped>

</style>
