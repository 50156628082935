import { render, staticRenderFns } from "./PerPageSelector.vue?vue&type=template&id=cf94a3da&scoped=true&"
import script from "./PerPageSelector.vue?vue&type=script&lang=js&"
export * from "./PerPageSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf94a3da",
  null
  
)

export default component.exports