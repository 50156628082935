<template>
    <div class="row filter-sec">
        <div class="col-md-3">
            <b-form-group label="Month">
               <month-selector
                   :maxMonth="filters.month.maxEnd"
                   :defaultMonth="filterMonth"
                   @change="setFilterMonth"
               ></month-selector>
            </b-form-group>
        </div>
        <div class="col-md-3">
            <b-form-group label="Churn Check">
                <b-form-select v-model="filters.churnType.selected" :options="filters.churnType.options"></b-form-select>
            </b-form-group>
        </div>
        <div class="col-md-2">
            <b-button class="filter-sec__submit" variant="primary" @click="submitFilter">Search</b-button>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import MonthSelector from '../../includes/MonthSelector'

export default {
  name: 'ListFilter',
  props: {
    month: {},
    splitsTotals: {}
  },
  components: {
    MonthSelector
  },
  data () {
    return {
      filters: {
        month: {
          selected: {
            month: '',
            year: ''
          },
          maxEnd: ''
        },
        churnType: {
          selected: 'all',
          options: [
            {value: 'all', text: 'Soft/Hard Churn'},
            {value: 'soft', text: 'Soft Churn'},
            {value: 'hard', text: 'Hard Churn'},
            {value: null, text: 'None'}
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      filterMonth: 'activeTrainers/getFilterMonth'
    })
  },
  mounted () {
    this.initFilter()
  },
  methods: {
    ...mapMutations({
      setFilterChurnType: 'activeTrainers/setFilterChurnType',
      setFilterMonth: 'activeTrainers/setFilterMonth'
    }),
    submitFilter () {
      this.setFilterChurnType(this.filters.churnType.selected)
      this.$emit('submit')
    },
    initFilter () {
      this.filters.month.maxEnd = this.filterMonth
    }
  }
}
</script>

<style scoped>

</style>
