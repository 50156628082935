import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default {
  namespaced: true,
  state: {
    collapsed: true,
    items: [
      {
        header: true,
        title: 'Main Navigation',
        hiddenOnCollapse: true
      },
      {
        href: '/',
        title: 'Dashboard',
        icon: 'fas fa-tachometer-alt'
      },
      {
        href: '/overview',
        title: 'Trainer Revenue',
        icon: 'fas fa-chart-pie'
      },
      {
        href: '/active-trainers',
        title: 'Active Trainers',
        icon: 'fa fa-user'
      },
      {
        href: '/churned-trainers',
        title: 'Churned Trainers',
        icon: 'fa fa-user-slash'
      },
      {
        href: '/lives-changed',
        title: 'Lives Changed (Quarterly)',
        icon: 'fa fa-heart'
      },
      {
        href: '/lives-changed-daily',
        title: 'Lives Changed (Daily)',
        icon: 'fa fa-football-ball'
      },
      {
        href: '/monthly-revenue',
        title: 'Monthly Revenue',
        icon: 'fa fa-dollar-sign'
      },
      {
        href: '/top-products',
        title: 'Top Products',
        icon: 'fa fa-boxes'
      }
    ],
    options: {
      collapsed: true,
      showOneChild: false,
      expandedWidth: '300px'
    }
  },
  mutations: {
    setCollapsed (state, collapsed) {
      state.collapsed = collapsed
    }
  },
  actions: {},
  getters: {
    collapsed (state) {
      return state.collapsed
    },
    items (state) {
      return state.items
    },
    options (state) {
      return state.options
    }
  }
}
