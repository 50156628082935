<template>
    <div class="per-page">
        <label>
            Per Page:
        </label>
        <b-form-select v-model="selectedPerPage" :options="options" size="sm" class="mb-2" @change="perPageChange"></b-form-select>
    </div>
</template>

<script>
export default {
  name: 'PerPageSelector',
  props: {
    perPage: {
      default: ''
    }
  },
  data () {
    return {
      selectedPerPage: 15,
      options: [15, 50, 100, 250, 500]
    }
  },
  mounted () {
    this.selectedPerPage = this.perPage
  },
  watch: {
    perPage (value) {
      this.selectedPerPage = value
    }
  },
  methods: {
    perPageChange (perPage) {
      this.$emit('perPageChanged', perPage)
    }
  }
}
</script>

<style scoped>

</style>
