<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover">
            <thead>
            <tr class="table-title">
                <th scope="col" width="10%">Trainer ID</th>
                <th scope="col" width="50%">Trainer Name</th>
                <th scope="col" width="40%">Stripe ID</th>
<!--                <th scope="col" class="text-right" width="20%">App Fee</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="record in records" :key="record.id">
                <td>{{record.id}}</td>
                <td>{{record.name}}</td>
                <td>{{record.stripe_id}}</td>
<!--                <td class="text-right">{{formatAmountDefault(record.total_app_fee)}}</td>-->
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'ListTable',
  props: {
    records: {}
  }
}
</script>

<style scoped>

</style>
