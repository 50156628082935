import router from '../routes/index'
import AuthService from './AuthService'
import store from '../store/index'

export default class ExceptionHandler {
  constructor () {
    if (!ExceptionHandler.instance) {
      ExceptionHandler.instance = this
    }
    return ExceptionHandler.instance
  }

  handleApiError (error) {
    return new Promise((resolve) => {
      switch (error.status) {
        case 401:
          this.handleUnauthenticated()
          break

        case 422:
          this.handValidationError(error.data)
          break

        default:
          this.handleServerError()
          break
      }
      resolve()
    })
  }

  handValidationError (errors) {
    if (store.getters.getGlobalErrorHandling) {
      let validationErrors = errors.errors
      Object.keys(validationErrors).forEach(function (value) {
        alert(validationErrors[value][0])
      })
    }
  }

  handleServerError () {
    alert('Something went wrong. Please try again shortly')
  }

  handleUnauthenticated () {
    let authService = new AuthService()
    authService.removeStorageAuth()
    authService.syncInstanceAuth()
    router.push('login')
  }
}
