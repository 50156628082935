<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover">
            <thead>
            <tr class="table-title">
                <th scope="col" width="10%">Month</th>
                <th scope="col">Trainer Name</th>
                <th scope="col">Stripe ID</th>
                <th scope="col">Product ID</th>
                <th scope="col">Price Name</th>
                <th scope="col">Price ID</th>
                <th scope="col">Price Amount</th>
                <th scope="col">Price Frequency</th>
                <th scope="col">Price Interval Count</th>
                <th scope="col">Number of Transactions</th>
                <th scope="col" class="text-right">Value of Transactions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="record in records" :key="record.id">
                <td>{{$moment(record.month).format('MMM-YYYY')}}</td>
                <td>{{record.trainer_name}}</td>
                <td>{{record.stripe_id}}</td>
                <td>{{record.product_id}}</td>
                <td>{{record.plan_name}}</td>
                <td>{{record.plan_id}}</td>
                <td>{{record.plan_raw_amount}} ({{record.plan_currency}})</td>
                <td>{{record.plan_interval}}</td>
                <td>{{record.plan_interval_count}}</td>
                <td>{{record.transactions_count}}</td>
                <td class="text-right">{{formatAmountDefault(record.total_amount)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'ListTable',
  props: {
    records: {}
  }
}
</script>

<style scoped>

</style>
