<template>
    <div class="container-fluid">
        <div class="page-title-sec">
            <h3 v-if="trainerName">{{trainerName}} Trainer Report</h3>
            <h3 v-else>Please wait</h3>
            <button class="btn btn-primary"
                    @click="loadCommissionRatesPopup()">
                Commission Rates
            </button>
            <button class="btn btn-primary" @click="goBack">Go Back</button>
        </div>

        <splits-filter @submit="filterSubmit"></splits-filter>
        <div class="card mt-3 pt-4">
            <div class="container-fluid">
                <div v-if="splitsCount && splitsCount > 0">
                    <paginator :rowCount="splitsCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                    <splits-table :splits="splits"
                                  :splits-totals="splitsTotals"></splits-table>
                    <paginator :rowCount="splitsCount"
                               :perPage="paginationPerPage"
                               :currentPage="paginationCurrentPage"
                               @pageChanged="pageChanged"
                               @perPageChanged="perPageChanged"></paginator>
                </div>
                <div class="pb-4 text-center" v-else>
                    <p>No records found.</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import RevenuesService from '../../services/RevenuesService'
import {mapActions, mapGetters, mapMutations} from 'vuex'
import SplitsFilter from './includes/SplitsFilter'
import Paginator from '../includes/pagination/Paginator'
import SplitsTable from './includes/SplitsTable'

export default {
  name: 'MainPage',
  data () {
    return {
      trainerName: '',
      trainerId: '',
      params: this.$route.query
    }
  },
  computed: {
    ...mapGetters({
      splitsTotals: 'singleTrainerSplits/getSplitsTotals',
      splits: 'singleTrainerSplits/getSplits',
      splitsCount: 'singleTrainerSplits/getSplitsTotalCount',
      currency: 'singleTrainerSplits/getFilterCurrency',
      paginationCurrentPage: 'singleTrainerSplits/getPaginationCurrentPage',
      paginationPerPage: 'singleTrainerSplits/getPaginationPerPage'
    })
  },
  watch: {
    splits () {
      if (this.splits && this.splits[0]) {
        this.trainerName = this.splits[0].trainer_name
        this.trainerId = this.splits[0].trainer_id
      }
    }
  },
  components: {
    SplitsTable,
    Paginator,
    SplitsFilter
  },
  mounted () {
    this.loadSplits(this.params.trainerId)
    this.loadSplitTotals(this.params.trainerId)
  },
  methods: {
    ...mapMutations({
      showCommissionRatesPopup: 'commissionRatesPopup/showPopup',
      setCommissionRatesPopupRates: 'commissionRatesPopup/setRates',
      setCommissionRatesPopupTrainer: 'commissionRatesPopup/setTrainer',

      showMonthlyRevenuesPopup: 'monthlyRevenuesPopup/showPopup',

      setPaginationActivePage: 'singleTrainerSplits/setPaginationActivePage',
      setPaginationPerPage: 'singleTrainerSplits/setPaginationPerPage',
      setFilterSubmit: 'singleTrainerSplits/setFilterSubmit'
    }),
    ...mapActions({
      loadSplits: 'singleTrainerSplits/loadSplits',
      loadSplitTotals: 'singleTrainerSplits/loadSplitTotals',
      loadMonthlyRevenues: 'monthlyRevenuesPopup/loadRevenues'
    }),
    filterSubmit () {
      this.setPaginationActivePage(1)
      this.loadSplits(this.params.trainerId)
      this.loadSplitTotals(this.params.trainerId)
    },
    loadCommissionRatesPopup () {
      // TODO: pass trainer object (refactor point)
      // TODO: move this to store actions
      this.showLoading()
      let splitService = new RevenuesService()
      splitService.getRates(this.trainerId)
        .then((res) => {
          this.setCommissionRatesPopupTrainer({
            name: this.trainerName
          })
          this.setCommissionRatesPopupRates(res.data.rates)
          this.showCommissionRatesPopup()
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    loadMonthlyRevenueBreakdownPopup () {
      this.loadMonthlyRevenues({
        trainerId: this.trainerId,
        currency: this.currency
      })
        .then(() => {
          this.showMonthlyRevenuesPopup()
        })
    },
    goBack () {
      this.$router.push('/')
    },
    perPageChanged (perPage) {
      this.setPaginationPerPage(perPage)
      this.loadSplits(this.params.trainerId)
    },
    pageChanged (page) {
      this.setPaginationActivePage(page)
      this.loadSplits(this.params.trainerId)
    }
  }
}
</script>

<style scoped>

</style>
