<template>
    <div class="mt-3">
        <div class="row">
            <div class="col-md-6">
                <per-page-selector :perPage="perPage" @perPageChanged="perPageChanged"></per-page-selector>
            </div>
            <div class="col-md-6">
                <page-selector :totalRows="rowCount"
                          :perPage="perPage"
                          :activePage="currentPage"
                          @pageChanged="pageChanged"></page-selector>
            </div>
        </div>
    </div>
</template>

<script>
import PageSelector from './includes/PageSelector'
import PerPageSelector from './includes/PerPageSelector'
export default {
  name: 'Paginator',
  props: {
    rowCount: {
      default: ''
    },
    perPage: {
      default: ''
    },
    currentPage: {
      default: ''
    }
  },
  components: {
    PageSelector,
    PerPageSelector
  },
  methods: {
    pageChanged (page) {
      this.$emit('pageChanged', page)
    },
    perPageChanged (perPage) {
      this.$emit('perPageChanged', perPage)
    }
  }
}
</script>

<style scoped>

</style>
