<template>
    <b-modal
        :visible="visible"
        :title="trainer.name + ' Commission Rates'"
        @hide="hide"
        size="xl"
        hide-footer
    >
        <table class="table table-bordered table-sm table-hover">
            <thead>
            <tr class="font-weight-bold">
                <td class="text-center">
                    Date
                </td>
                <td colspan="6"  class="text-center">
                    Macro
                </td>
                <td colspan="6" class="text-center">
                    Reseller
                </td>
            </tr>

            <tr class="table-title">
                <td></td>
                <td class="text-right">Platform</td>
                <td class="text-right">Support</td>
                <td class="text-right">Advertising</td>
                <td class="text-right">Marketing</td>
                <td class="text-right">Other</td>
                <td class="text-right">Total</td>
                <td class="text-right">Platform</td>
                <td class="text-right">Support</td>
                <td class="text-right">Advertising</td>
                <td class="text-right">Marketing</td>
                <td class="text-right">Other</td>
                <td class="text-right">Total</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="record in records" :key="record.date">
                <td>{{record.from_date}} - {{record.to_date}}</td>
                <td class="text-right">{{record.macro_platform}}%</td>
                <td class="text-right">{{record.macro_support}}%</td>
                <td class="text-right">{{record.macro_advertising}}%</td>
                <td class="text-right">{{record.macro_marketing}}%</td>
                <td class="text-right">{{record.macro_other}}%</td>
                <td class="text-right">{{record.macro_total}}%</td>
                <td class="text-right">{{record.reseller_platform}}%</td>
                <td class="text-right">{{record.reseller_support}}%</td>
                <td class="text-right">{{record.reseller_advertising}}%</td>
                <td class="text-right">{{record.reseller_marketing}}%</td>
                <td class="text-right">{{record.reseller_other}}%</td>
                <td class="text-right">{{record.reseller_total}}%</td>
            </tr>
            </tbody>

        </table>
    </b-modal>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'CommissionRatesPopup',
  data () {
    return {
      records: []
    }
  },
  computed: {
    ...mapGetters({
      visible: 'commissionRatesPopup/visible',
      rates: 'commissionRatesPopup/rates',
      trainer: 'commissionRatesPopup/trainer'
    })
  },
  watch: {
    rates () {
      this.setRates()
    }
  },
  methods: {
    ...mapMutations({
      hide: 'commissionRatesPopup/hidePopup'
    }),
    setRates () {
      this.records = []
      let dates = this.rates.map(rate => {
        return rate.date
      })

      // remove duplicates
      dates = dates.filter(function (item, pos, self) {
        return self.indexOf(item) === pos
      })

      // sort
      dates = dates.sort(function (a, b) {
        let dateA = new Date(a)
        let dateB = new Date(b)
        return dateA - dateB
      })

      dates.forEach((date, dateKey) => {
        let toDate = 'Up to now'
        if (typeof dates[dateKey + 1] !== 'undefined') {
          toDate = this.$moment(dates[dateKey + 1]).subtract(1, 'days').format('YYYY-MM-DD')
        }

        // declare defaults
        let recordGroup = {
          from_date: this.$moment(date).format('YYYY-MM-DD'),
          to_date: toDate,
          macro_platform: 0,
          macro_support: 0,
          macro_advertising: 0,
          macro_marketing: 0,
          macro_other: 0,
          macro_total: 0,
          reseller_platform: 0,
          reseller_support: 0,
          reseller_advertising: 0,
          reseller_marketing: 0,
          reseller_other: 0,
          reseller_total: 0
        }

        this.rates.forEach((rate) => {
          if (rate.date === date) {
            recordGroup[rate.commission_type] = parseFloat((rate.rate * 100))
          }
        })

        // set totals
        recordGroup.macro_total = (recordGroup.macro_platform +
                        recordGroup.macro_support +
                        recordGroup.macro_advertising +
                        recordGroup.macro_marketing +
                        recordGroup.macro_other)

        recordGroup.reseller_total = (recordGroup.reseller_platform +
                        recordGroup.reseller_support +
                        recordGroup.reseller_advertising +
                        recordGroup.reseller_marketing +
                        recordGroup.reseller_other)

        this.records.push(recordGroup)
      })
    }
  }
}
</script>

<style scoped>

</style>
