<template>
    <b-navbar toggleable="lg" type="light">
        <div class="container-fluid">
            <b-navbar-brand href="javascript:" @click="goHome">
                <img src="./../../assets/images/logo.png" alt="" style="width: 130px; margin-bottom: 6px;">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-navbar-nav v-if="stats.last_synced_at">
                        <p class="nav-link">(Last Synced: {{stats.last_synced_at}} UTC)</p>
                    </b-navbar-nav>
                    <b-nav-item-dropdown right>
                        <!-- Using 'button-content' slot -->
                        <template #button-content>
                            <em>{{user.name}}</em>
                        </template>
                        <b-dropdown-item href="javascript:" @click="logOut">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
</template>

<script>
import AuthService from '../../services/AuthService'
import {mapGetters} from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters({
      user: 'getUser',
      stats: 'getStats'
    })
  },
  methods: {
    goHome () {
      this.$router.push('/')
    },
    logOut () {
      let authService = new AuthService()
      authService
        .signOut()
        .finally(() => {
          this.$router.push('login')
        })
    }
  }
}
</script>

<style scoped>

</style>
