import Vue from 'vue'
import Vuex from 'vuex'

import AllTrainersSplits from './modules/AllTrainersSplits'
import SingleTrainerSplits from './modules/SingleTrainerSplits'
import CommissionRatesPopup from './modules/CommissionRatesPopup'
import SideBar from './modules/SideBar'
import ActiveTrainers from './modules/ActiveTrainers'
import Dashboard from './modules/Dashboard'
import ChurnedTrainers from './modules/ChurnedTrainers'
import MonthlyRevenuesPopup from './modules/MonthlyRevenuesPopup'
import LivesChanged from './modules/LivesChanged'
import LivesChangedDaily from './modules/LivesChangedDaily'
import MonthlyRevenueBreakdown from './modules/MonthlyRevenueBreakdown'
import FileExport from './modules/FileExport'
import TopProducts from './modules/TopProducts'
import StatsService from '../services/StatsService'

Vue.use(Vuex)
Vue.config.debug = true

export default new Vuex.Store({
  modules: {
    allTrainersSplits: AllTrainersSplits,
    singleTrainerSplits: SingleTrainerSplits,
    commissionRatesPopup: CommissionRatesPopup,
    monthlyRevenuesPopup: MonthlyRevenuesPopup,
    sideBar: SideBar,
    activeTrainers: ActiveTrainers,
    churnedTrainers: ChurnedTrainers,
    dashboard: Dashboard,
    livesChanged: LivesChanged,
    livesChangedDaily: LivesChangedDaily,
    monthlyRevenueBreakdown: MonthlyRevenueBreakdown,
    fileExport: FileExport,
    topProducts: TopProducts
  },
  state: {
    appMounted: false,
    user: {},
    stats: {},
    accessToken: '',
    globalErrorHandling: true,
    loading: 0
  },
  mutations: {
    setGlobalErrorHandling (state, payload) {
      state.globalErrorHandling = payload
    },
    setLoading (state, payload) {
      if (payload) {
        state.loading++
      } else {
        if (state.loading > 0) {
          state.loading--
        }
      }
    },
    setAppMounted (state, payload) {
      state.appMounted = payload
    },
    setUser (state, payload) {
      state.user = payload
    },
    setStats (state, payload) {
      state.stats = payload
    },
    setAccessToken (state, payload) {
      state.accessToken = payload
    },
    removeAuth (state) {
      state.user = {}
      state.accessToken = ''
    }
  },
  actions: {
    loadStats ({commit}) {
      return new Promise((resolve, reject) => {
        commit('setLoading', true)
        let statsService = new StatsService()
        statsService
          .getStats()
          .then((res) => {
            commit('setStats', res.data)
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            commit('setLoading', false)
          })
      })
    }
  },
  getters: {
    getGlobalErrorHandling (state) {
      return state.globalErrorHandling
    },
    getLoading (state) {
      return state.loading
    },
    getAppMounted (state) {
      return state.appMounted
    },
    checkAuth () {
      if (localStorage.getItem('user')) {
        return true
      } else {
        return false
      }
    },
    getUser (state) {
      return state.user
    },
    getStats (state) {
      return state.stats
    },
    getAccessToken (state) {
      return state.accessToken
    }
  }
})
