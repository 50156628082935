export default {
  methods: {
    showLoading () {
      this.$store.commit('setLoading', true)
    },
    hideLoading () {
      this.$store.commit('setLoading', false)
    },
    formatDateDefault (date) {
      return this.$moment(date).format('MMMM Do YYYY')
    },
    formatAmountDefault (num) {
      if (!num) {
        num = 0
      }

      //TODO: remove redundancy
      return parseFloat(num).toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    addThousandSeparator (number) {
      return number.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getInt (number) {
      return parseInt(number)
    }
  }
}
