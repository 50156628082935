<template>
    <div class="table-responsive">
        <table class="table table-bordered table-sm table-hover">
            <thead>
            <tr class="total-sec">
                <td colspan="2">
                    Total
                </td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.trainer_received)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.stripe_fee)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.app_fee)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.app_fee - splitsTotals.macro_total)}}
                </td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.macro_total)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.macro_platform)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.macro_support)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.macro_advertising)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.macro_marketing)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.macro_other)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_total)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_platform)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_support)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_advertising)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_marketing)}}</td>
                <td class="text-right">{{formatAmountDefault(splitsTotals.reseller_other)}}</td>
            </tr>
            <tr class="table-title">
                <th scope="col">Stripe ID</th>
                <th scope="col">Trainer Name</th>
                <th scope="col" class="text-right">Trainer Received</th>
                <th scope="col" class="text-right">Stripe Fee</th>
                <th scope="col" class="text-right">App Fee</th>
                <th scope="col" class="text-right">Variance</th>
                <th scope="col" class="text-right">Macro Total</th>
                <th scope="col" class="text-right">Macro Platform</th>
                <th scope="col" class="text-right">Macro Support</th>
                <th scope="col" class="text-right">Macro Advertising</th>
                <th scope="col" class="text-right">Macro Marketing</th>
                <th scope="col" class="text-right">Macro Other</th>
                <th scope="col" class="text-right">Reseller Total</th>
                <th scope="col" class="text-right">Reseller Platform</th>
                <th scope="col" class="text-right">Reseller Support</th>
                <th scope="col" class="text-right">Reseller Advertising</th>
                <th scope="col" class="text-right">Reseller Marketing</th>
                <th scope="col" class="text-right">Reseller Other</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="split in stripeTrainersSplits" :key="split.id">
                <th scope="row">{{JSON.parse(split.stripe).account_id}}</th>
                <td>
                    <a href="javascript:" @click="splitTrainerClicked(split.id)">{{split.trainer_name}}</a>
                    <font-awesome-icon class="split-view-icon" :icon="['fas', 'chart-pie']"
                                       @click="splitSharesClicked(split.id, split.trainer_name)"/>
                </td>
                <td class="text-right">{{formatAmountDefault(split.trainer_received)}}</td>
                <td class="text-right">{{formatAmountDefault(split.stripe_fee)}}</td>
                <td class="text-right">{{formatAmountDefault(split.app_fee)}}</td>
                <td class="text-right">{{formatAmountDefault(split.app_fee - split.macro_total)}}</td>
                <td class="text-right">{{formatAmountDefault(split.macro_total)}}</td>
                <td class="text-right">{{formatAmountDefault(split.macro_platform)}}</td>
                <td class="text-right">{{formatAmountDefault(split.macro_support)}}</td>
                <td class="text-right">{{formatAmountDefault(split.macro_advertising)}}</td>
                <td class="text-right">{{formatAmountDefault(split.macro_marketing)}}</td>
                <td class="text-right">{{formatAmountDefault(split.macro_other)}}</td>
                <td class="text-right">{{formatAmountDefault(split.reseller_total)}}</td>
                <td class="text-right">{{formatAmountDefault(split.reseller_platform)}}</td>
                <td class="text-right">{{formatAmountDefault(split.reseller_support)}}</td>
                <td class="text-right">{{formatAmountDefault(split.reseller_advertising)}}</td>
                <td class="text-right">{{formatAmountDefault(split.reseller_marketing)}}</td>
                <td class="text-right">{{formatAmountDefault(split.reseller_other)}}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
  name: 'SplitsTable',
  props: {
    splits: [],
    splitsTotals: {}
  },
  computed: {
    stripeTrainersSplits () {
      return this.splits.filter(function (split) {
        return split.stripe != null
      })
    }
  },
  methods: {
    splitTrainerClicked (trainerId) {
      this.$emit('splitTrainerClicked', trainerId)
    },
    splitSharesClicked (trainerId, trainerName) {
      this.$emit('splitSharesClicked', trainerId, trainerName)
    }
  }
}
</script>

<style scoped>

</style>
